
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import AppLoader from 'components/atomic/molecules/app-loader';
import Router from 'next/router';
import React from 'react';
import Routes from 'utils/routes';
export default function Home() {
    React.useEffect(() => {
        Router.push(Routes.SimulationsHome);
    }, []);
    return <AppLoader />;
}

    async function __Next_Translate__getStaticProps__18943f39238__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18943f39238__ as getStaticProps }
  